import React from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import ProductHeader from "gatsby-theme-rothenberger/src/components/blocks/ProductHeader"
import ProductOverviewBlock from "gatsby-theme-rothenberger/src/components/blocks/ProductOverviewBlock"
import VariantCardsBlock from "gatsby-theme-rothenberger/src/components/blocks/VariantCardsBlock"
import DividerBlock from "gatsby-theme-rothenberger/src/components/blocks/DividerBlock"
import SEO from "gatsby-theme-rothenberger/src/@yumgmbh/gatsby-theme-yum-components/components/modules/Seo"
import { graphql } from "gatsby"
import striptags from "striptags"

const ProductTemplate = ({ data }) => {
  const product = data.shopifyProduct
  const relatedProducts = data.relatedProducts

  const breadcrumbs = [
    {
      link: "/de-de/produkte",
      link_text: "Produkte",
    },
    {
      link: `/de-de/produkte/${product.collections[0]?.handle}`,
      link_text: product.collections[0]?.title,
    },
  ]

  const relatedProductsItems = relatedProducts.nodes.map((variant) => {
    if (variant.totalInventory > 0) {
      return {
        slug: `/de-de/produkte/${variant?.handle}`,
        headline: variant.title,
        text: variant.collections[0]?.title,
        price: {
          price: variant.variants[0].presentmentPrices[0].price,
          compareAtPrice:
            variant.variants[0].presentmentPrices[0].compareAtPrice,
        },
        image: variant.featuredImage
          ? variant.featuredImage.src
          : process.env.GATSBY_IMG_PLACEHOLDER,
      }
    }
    return null
  })

  return (
    <>
      <Layout language="de">
        <SEO
          title={`${product.title} | ${product.collections[0]?.title}`}
          description={striptags(product.descriptionHtml).replace(
            /\n{1,}/g,
            " "
          )}
          og_image={product.media[0].preview.image.src}
          language="de"
        />
        <ProductHeader
          block={{ headline: product.title, _uid: product.shopifyId }}
          breadcrumbs={breadcrumbs}
        />
        <ProductOverviewBlock
          block={{
            title: product.title,
            productId: product.shopifyId,
            variantId: product.variants[0].shopifyId,
            text: product.descriptionHtml,
            choosen_sku: product.variants[0].sku,
            product_price: {
              price: product.variants[0].presentmentPrices[0].price,
              compareAtPrice:
                product.variants[0].presentmentPrices[0].compareAtPrice,
            },
            product_images: [...product.media],
            total_inventory: product.totalInventory,
          }}
        />

        {relatedProductsItems?.length > 0 && (
          <>
            <DividerBlock block={{}} />
            <VariantCardsBlock
              block={{
                variant_items: relatedProductsItems,
              }}
            />
          </>
        )}
      </Layout>
    </>
  )
}

export const query = graphql`
  query ProductQuery($shopifyId: String, $tags: [String]) {
    shopifyProduct(shopifyId: { eq: $shopifyId }) {
      title
      shopifyId
      descriptionHtml
      totalInventory
      media {
        preview {
          image {
            altText
            src
          }
        }
      }
      variants {
        shopifyId
        sku
        presentmentPrices {
          compareAtPrice {
            amount
            currencyCode
          }
          price {
            amount
            currencyCode
          }
        }
      }
      collections {
        handle
        title
      }
      metafields {
        value
        key
      }
    }
    relatedProducts: allShopifyProduct(
      limit: 10
      filter: {
        tags: { in: $tags }
        shopifyId: { ne: $shopifyId }
        totalInventory: { ne: 0 }
      }
    ) {
      nodes {
        totalInventory
        title
        handle
        featuredImage {
          src
        }
        collections {
          handle
          title
        }
        variants {
          sku
          presentmentPrices {
            compareAtPrice {
              amount
              currencyCode
            }
            price {
              amount
              currencyCode
            }
          }
        }
      }
    }
  }
`

export default ProductTemplate
